// Styles for commonly used HTML elements

// Root elements
html,
body {
  background: $theme-white;

  a {
    color: $theme-white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

}

.container {
  max-width: 1250px;
  padding: 0 10px;
}