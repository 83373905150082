// Colors

// SCSS Color Variables | Named with this: http://chir.ag/projects/name-that-color
$white: #F5F5F5;
$dark: #252525;
$persianRed: #d32f2f;

// Theme CSS Color Variables
:root {
	--theme-dark: #{$dark};
	--theme-white: #{$white};
	--theme-error: #{$persianRed};
}

// Theme SCSS Color Variables
$theme-dark: var(--theme-dark);
$theme-white: var(--theme-white);
$theme-error: var(--theme-error);