// Specific style for contact page
.contact {
  height: 90vh;
  display: flex;
}

.contact__credentials {
  text-align: center;
  display: grid;
  place-items: center;
  width: 50vw;
  font-size: 1.5rem;
  font-weight: bold;

  span {
    font-size: 1rem;
    font-weight: lighter;
  }
}

.contact__design {
  background-color: #252525;
  display: grid;
  place-items: center;
  color: white;
  width: 50vw;

  h1 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.5rem;
    width: 350px;
  }
}

@include media("<=tablet") {

  .contact__design {

    p {
      width: 300px;
    }
  }
}

@include media("<=phone") {
  .contact__design {
    display: none;
    visibility: hidden;
  }

  .contact__credentials {
    width: 100vw;
  }
}