// Typography rules

// Root elements
:root {
	--font-family-sans-serif: 'Open Sans', sans-serif;
}

html,
body {
	font-family: var(--font-family-sans-serif) !important;
	color: $theme-dark;
	font-weight: 400;
}