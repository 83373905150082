// Specific style for home page

.home__start {
  position: relative;
  display: grid;
  place-items: center;

  h1 {
    margin: 0;
    z-index: 2;
    position: absolute;
    color: $theme-white;
    font-size: 5.5rem;
    opacity: 100%;
  }

  img {
    width: 100vw;
    display: block;
    height: 100vh;
    object-fit: cover;
  }
}

.home__buehl {
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
}

.home__card__container {
  position: relative;
  display: grid;
  place-items: center;
  color: white;

  img {
    width: 100vw;
    min-height: 1000px !important;
    display: block;
    height: 100vh;
    object-fit: cover;
  }
}

.home__card {
  display: grid;
  place-items: center;

  p {
    display: block;
    margin: 70px auto;
    max-width: 1000px;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
  }
}

.home__card-background {
  position: absolute;
  padding: 20px;
}


.home__price {
  height: 100vh;
  min-height: 1000px !important;
  width: 100vw;
  display: grid;
  place-items: center;
}

.home__price__special {
  margin-top: 0 !important;
}

.home__price__inner {
  padding: 0 20px;

  dl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 0;

    dt, dd {
      margin: 0;
      font-size: 2rem;
    }

    dd {
      font-weight: bold;
    }
  }
}

@include media("<=tablet") {

  .swiper {
    height: 700px !important;
    width: 700px !important;
  }

  .home__start {
    h1 {
      text-align: center;
    }
  }
}

@include media("<=phone") {

  .swiper {
    border: 10px solid black;
    height: 300px !important;
    width: 300px !important;
  }

  .home__start {
    h1 {
      font-size: 3rem;
    }
  }

  .home__card {
    p {
      font-size: 1.25rem;
    }

    img {
      filter: brightness(50%);
    }
  }

  .home__price {
    height: 80vh;
  }

  .home__price__inner {
    dl {
      dt, dd {
        font-size: 0.75rem;
        margin-bottom: 10px;
      }

      dd {
        text-align: right;
      }
    }

    span p{
      text-align: left;
      margin: 0;
      font-size: .5rem;
      font-weight: normal;
    }
  }
}

.swiper {
  border-top: 10px solid black;
  border-bottom: 10px solid black;
  height: 100vh;
  width: 100vw;
  margin-top: 100px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}