// Forms and form inputs styling
.headline {
  text-align: center;

  h1 {
    font-size: 4rem;
    margin: 0;
  }

  h2 {
    font-size: 3rem;
    margin: 0;

    &::before, &::after {
      display: inline-block;
      content: "";
      border-bottom: .3rem solid black;
      width: 4rem;
      margin: 0 .5rem;
    }
  }

  .white {
      &::before, &::after {
        border-bottom: .3rem solid white !important;
      }
  }
}

@include media("<=phone") {
  .headline {
    h1 {
      font-size: 3rem;
    }

    h2 {
      font-size: 2rem;

      &::before, &::after {
        width: 2rem;
      }
    }
  }
}

@include media("<=behinderung") {
    .headline {
      h1 {
        font-size: 2.5rem;
      }

      h2 {
        font-size: 1.5rem;

        &::before, &::after {
          width: 1rem;
        }
      }
    }
}