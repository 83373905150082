// Header

.header__wrapper {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.2);
}

.header__wrapper__inner {
  margin: 0 auto;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $white;
  border-bottom: white solid 2px;

  img {
    width: 100px;
    object-fit: cover;
  }

  h1 {
    text-align: center;
  }

  ul {
    display: flex;
    gap: 20px;
    list-style: none;

    li {
      a {
        font-weight: bold;
        font-size: 1.2rem;
      }
    }
  }

  svg {
    display: none;
    visibility: hidden;
  }
}

.header__dropdown {
  padding: 20px;

  ul {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px;
    list-style: none;

    a {
      font-size: 1.5rem;
    }
  }
}

@include media("<=tablet") {
  .header__wrapper__inner {

    ul {
      display: none;
      visibility: hidden;
    }

    svg {
      display: block;
      visibility: visible;
      font-size: 35px;
    }
  }
}