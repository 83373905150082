// Footer

footer {
  text-align: center;
  background-color: #252525;
  color: white;
  padding: 30px;

  h1 {
    font-size: 1.5rem;
  }

  p {
    margin: 0
  }
}